export const menu1 = [{
  title: 'Solutions',
  link: '#solutions'
}, {
  title: 'Pricing',
  link: '#pricing'
}, {
  title: 'Docs',
  link: '#docs'
}, {
  title: 'More',
  link: '#more'
}]

export const menu2 = [{
  title: '最新消息',
  link: '#product'
}, {
  title: '方案',
  link: '#features'
}, {
  title: '申購',
  link: '#marketplace'
}, {
  title: '成效',
  link: '#company'
}]

export default {
  menu1,
  menu2
}
