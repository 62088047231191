





































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
// import { readUserProfile } from '@/store/main/getters';
import { readUserItemsOngoing } from '@/store/main/getters';
import { dispatchGetUserItemsOngoing } from '@/store/main/actions';
import { readUserItems } from '@/store/main/getters';
import { dispatchGetUserItems } from '@/store/main/actions';
import { readHasAdminAccess } from '@/store/main/getters';
import { readConstants, readUserWallet, readUserProfile } from '@/store/main/getters';
import { dispatchGetConstants, dispatchGetUserWallet, dispatchGetUserProfile } from '@/store/main/actions';

import UserNavChart30 from "../components/user_nav_30_days.vue";
Vue.component('UserNavChart30', UserNavChart30);

import UserNavChart7 from "../components/user_nav_7_days.vue";
Vue.component('UserNavChart7', UserNavChart7);

@Component
export default class Dashboard extends Vue {
  // public switchUSDT = false;
  // public tokenType = 'TWD';
  // public switchDisplay() {
  //   if (this.switchUSDT === true) {
  //     this.tokenType = 'USDT';
  //     return '以 USDT 計算收益與 APR';
  //   } else {
  //     this.tokenType = '新臺幣';
  //     return '以 新臺幣 計算收益與 APR';
  //   }
  // }

  // my edit
  // public headers = [
  //   { text: '起始時間', sortable: true, value: 'startingTime', align: 'left'},
  //   { text: '項目', sortable: true, value: 'title', align: 'left'},
  //   { text: '起始價格', sortable: true, value: 'initialValues', align: 'left'},
  //   { text: '現在價格', sortable: true, value: 'currentValues', align: 'left'},
  //   { text: '收益', sortable: true, value: 'gains', align: 'left'},
  //   { text: '年化利率 (APR)', sortable: true, value: 'apr', align: 'left'},
  //   { text: '狀態', sortable: true, value: 'status', align: 'left'},
  //   { text: '約期', sortable: true, value: 'duration', align: 'left'},
  // ];

  public nav_graph_tab = null;

  public switchUSDT = false;
  public displayCurrency = 'USDT';
  public switchDisplay() {
    if (this.switchUSDT === false) {
      this.displayCurrency = 'USDT';
      return '現在顯示 USDⓢ ';
    } else {
      this.displayCurrency = 'TWD';
      if (this.constants) {
        return '現在顯示 新臺幣 ，換算匯率：' + Math.round(this.constants.USDT_price * Math.pow(10, 2)) / Math.pow(10, 2) + ' USDT / TWD';
      } else{
        return '現在顯示 新臺幣';
      }
    }
  }

  get userItemsOngoing() {
    // return readUserItemsOngoing(this.$store);
    return readUserItemsOngoing(this.$store);
  }

  get constants() {
    return readConstants(this.$store);
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  get userWallet() {
    return readUserWallet(this.$store);
  }

  get user_ongoing_asset() {
    if (this.userWallet) {
      return this.userWallet.ongoing_asset_USD;
    }
  }

  get user_total_asset() {
    if (this.userWallet) {
      return this.userWallet.total_asset_USD;
    }
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  // token price and price change
  get BTC_price() {
    if (this.constants) {
      return this.constants.BTC_price;
    }
  }

  get ETH_price() {
    if (this.constants) {
      return this.constants.ETH_price;
    }
  }

  get BNB_price() {
    if (this.constants) {
      return this.constants.BNB_price;
    }
  }

  get BTC_price_change() {
    if (this.constants) {
      return this.constants.BTC_price_change;
    }
  }

  get ETH_price_change() {
    if (this.constants) {
      return this.constants.ETH_price_change;
    }
  }

  get BNB_price_change() {
    if (this.constants) {
      return this.constants.BNB_price_change;
    }
  }

  get dailyPnL() {
    if (this.userProfile) {
      let this_day_asset = this.userProfile.navs.values[this.userProfile.navs.values.length-1];
      let the_day_before_asset = this.userProfile.navs.values[this.userProfile.navs.values.length-2];
      let PnL = this_day_asset - the_day_before_asset;
      return PnL;
    } else {
      return 0;
    }
  }

  get dailyApy() {
    if (this.userProfile) {
      let this_day_asset = this.userProfile.navs.values[this.userProfile.navs.values.length-1];
      let the_day_before_asset = this.userProfile.navs.values[this.userProfile.navs.values.length-2];
      let apy = (this_day_asset - the_day_before_asset) / the_day_before_asset * 365 * 100; // in %
      return apy;
    } else {
      return 0;
    }
  }

  public async mounted() {
    await dispatchGetUserItemsOngoing(this.$store);
    // await dispatchGetUserItems(this.$store);
    await dispatchGetConstants(this.$store);
    await dispatchGetUserWallet(this.$store);
    await dispatchGetUserProfile(this.$store);
  }

  // update version
  checkVersion() {
    const constants = readConstants(this.$store);

    let onlineVersion = "none";
    if (constants) {
      onlineVersion = constants.version;
    }

    let localVersion = localStorage.getItem('vers');
    let updateSignal = localStorage.getItem('upt');

    // check if we need to fresh the browser
    if (onlineVersion != "none" && localVersion != onlineVersion) { // detect newer online version
      localStorage.setItem('vers', onlineVersion);
      window.location.reload();
    } else if (updateSignal == 'refresh') { // detect refresh signal
      localStorage.setItem('upt', 'no');
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkVersion();
  }

  onResize() {
    this.isMobile = window.innerWidth < 960;
  }
}
