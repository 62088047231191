<template>
  <Toolbar1 />
</template>

<script>
import Toolbar1 from '@/components/ui/landing/toolbar/Toolbar1.vue'

export default {
  components: {
    Toolbar1
  }
}
</script>
